// ----------------------------------------------------------------------------
// Google Tag Manager Utilities
// ----------------------------------------------------------------------------

import { APP_ENV, ORDER_MAX_CASH_THRESHOLD } from "../../utils/config";

const EVENTS = {
  pageView: "screen_view",
  addToCart: "add_to_cart",
  beginCheckout: "begin_checkout",
  venueClick: "venue_click",
  serviceClick: "service_click",
  beginCheckoutOverThreshold: "begin_checkout_over_threshold",
  purchase: "purchase",
  cashAlertOverThreshold: "cash_alert_over_threshold"
}

function getDataLayer() {
  if (!window) { return [] }

  return window.dataLayer || [];
}

// Generic function that add the event to the dataLayer object
export function logAnalyticsEvent(event, payload = {}) {
  const dataLayer = getDataLayer();

  dataLayer.push(Object.assign({ event }, payload));
}

// Generic function that set custom user properties
export function gtmSetUserProperties(props) {
  gtag('set', 'user_properties', props);
}

export function logVenueClicked(venueName) {
  logAnalyticsEvent(EVENTS.venueClick, { venue_name: venueName })
}

export function logServiceClicked(serviceName) {
  logAnalyticsEvent(EVENTS.serviceClick, { service_name: serviceName })
}

export function logCashAlertOverThreshold() {
  logAnalyticsEvent(EVENTS.cashAlertOverThreshold)
}

export function logAddToCart(item, quantity) {
  try {
    logAnalyticsEvent(EVENTS.addToCart, {
      ecommerce: {
        items: [getItem(item, quantity)],
      }
    });
  } catch (err) {
    if (APP_ENV === "development") {
      console.error(err)
    }
  }
}

export function logBeginCheckout(line_items, totalAmount) {
  try {
    const ecommerce_items = getItems(line_items)

    logAnalyticsEvent(EVENTS.beginCheckout, {ecommerce: {
        items: ecommerce_items,
      }});

    if (totalAmount > ORDER_MAX_CASH_THRESHOLD) {
      logAnalyticsEvent(EVENTS.beginCheckoutOverThreshold, {value: totalAmount});
    }

  } catch (err) {
    if (APP_ENV === "development") {
      console.error(err)
    }
  }
}

export function logPurchase(order) {
  if (typeof window === "undefined") return
  if (typeof window._iub === "undefined") return
  if (!window._iub.csActivationDone) return

  try {
    const ecommerce_items = getItems(order.line_items)

    logAnalyticsEvent(EVENTS.purchase, { ecommerce: {
        purchase: {
          transaction_id: order.id,
          value: order.discounted_price_cents / 100,
          shipping: order.delivery_price_cents / 100,
          currency: "EUR",
          coupon: order.coupon_code || "",
          items: ecommerce_items,
        },
      }});
  } catch (err) {
    if (APP_ENV === "development") {
      console.error(err)
    }
  }
}

function getItems(line_items) {
  return Object.keys(line_items).map((id) => {
    const item = line_items[id]

    return getItem(item)
  })
}

function getItem(item, quantity = undefined) {
  return {
    item_name: item.name,
    item_id: item.id,
    item_price: item.price_cents / 100.0,
    item_category: item.section_title,
    quantity: quantity || item.quantity,
  }
}
