import Cookies from "js-cookie"

if (
  typeof onPageReady !== "undefined" ||
  typeof window.onPageReady !== "undefined"
) {
  onPageReady(function() {
    var coverElement = $(".cover-image-home");
    if (Cookies.get("header_img")) {
      coverElement.addClass("cover-image-home__" + Cookies.get("header_img"));
      return;
    }
    var randomCoverIndex = Math.floor(Math.random() * Math.floor(4));
    Cookies.set("header_img", randomCoverIndex, { expires: 1 });
    coverElement.addClass("cover-image-home__" + randomCoverIndex);
  });
}
