import 'core-js/stable'
import 'regenerator-runtime/runtime'

import ReactOnRails from "react-on-rails";

// Old Sprockets files
import "../utils/console_log"
import "../utils/page-ready"
import "../utils/affix"
import "../utils/alert-bar"
import "../utils/topbar_web" //@todo: Remove this
// import "../utils/mobile-app-banner" //@todo: Re add this banner
// end sprockets files

import "../bundles/CoverImageBackgroundHandler/background_handler"
import SignInUserLink from "../bundles/SignInUserLink/SignInUserLink";
import DeleteProfilingCookies from "../bundles/DeleteProfilingCookies/DeleteProfilingCookies";
import SearchPlacesForm from "../bundles/SearchPlacesForm/SearchPlacesForm"
import ShareUrlBox from "../bundles/SharingButtons/ShareUrlBox";
import InviteFriendForm from "../bundles/InviteFriendForm/InviteFriendForm"
import PaymentInProgressBanner from "../bundles/PaymentApp/PaymentInProgressBanner"


ReactOnRails.register({
  SignInUserLink,
  DeleteProfilingCookies,
  SearchPlacesForm,
  ShareUrlBox,
  InviteFriendForm,
  PaymentInProgressBanner
});

