import request from "superagent";
import { API_URL, getAuthorizationHeader } from "./base";

export const CreateOrder = (body, token) => {
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}orders`)
      .send(body)
      .set('Authorization', `Token token=${token}`)
      .set('Accept', 'application/json')
      .then(res => {
        resolve(res.body.result);
      })
      .catch(error => {
        reject(error);
    })
  });
}

export const GetOrder = (id) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}orders/${id}`)
      .set('Authorization', getAuthorizationHeader())
      .set('Accept', 'application/json')
      .end((error, res) => {
        error ? reject(error) : resolve(res.body.result);
      });
  });
}

export const GetOrderPayment = (id) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}orders/${id}/payment`)
      .set('Authorization', getAuthorizationHeader())
      .set('Accept', 'application/json')
      .end((error, res) => {
        error ? reject(error) : resolve(res.body.result);
      });
  });
}
