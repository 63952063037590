if (
  typeof onPageReady !== "undefined" ||
  typeof window.onPageReady !== "undefined"
) {
  onPageReady(function() {
    // var divHeight = parseInt($(".description").height());
    // var lineHeight = parseInt($(".description").css("line-height"));
    // var lines = divHeight / lineHeight;

    // if (lines > 3) {
    //   $(".read-more").show();
    //   $(".toggle-show-more").on("click", function() {
    //     $(".read-more").toggle();
    //     $(".overflow").toggleClass("open");
    //   });
    // }

    var MQL = 768;
    var header = $(".cd-header");
    var logoOrange = $(".hide-orange");
    var logoWhite = $(".logo-white");

    function showLogoOrange() {
      logoWhite.hide();
      logoOrange.show();
    }
    function showLogoWhite() {
      logoWhite.show();
      logoOrange.hide();
    }

    if ($(window).width() > MQL) {
      var headerHeight = header.height();
      $(window).on(
        "scroll",
        {
          previousTop: 0
        },
        function() {
          var currentTop = $(window).scrollTop();
          // if (lines > 3) {
          //   $(".overflow").removeClass("open");
          //   $(".read-more").show();
          // }
          if (currentTop < header.height()) {
            if (currentTop < this.previousTop) {
              header.removeClass("is-visible no-transparent");
              setTimeout(function() {
                header.addClass("shield-background animated fadeIn");
                header.removeClass("is-fixed");
                showLogoWhite();
              }, 250);
              return;
            }
            header.addClass("shield-background");
            header.removeClass("is-visible no-transparent");
            showLogoWhite();
            return;
          }
          header.addClass("is-fixed");
          if (currentTop < this.previousTop) {
            header.addClass("is-visible no-transparent");
            header.removeClass("shield-background fadeIn");
            showLogoOrange();
          } else {
            header.removeClass("is-visible");
          }
          this.previousTop = currentTop;
        }
      );
    }

    $(".frs .cd-primary-nav-trigger").on("click", function() {
      $(".cd-menu-icon").toggleClass("is-clicked");
      header.toggleClass("menu-is-open");

      if ($(".cd-primary-nav").hasClass("is-visible")) {

        $(".cd-primary-nav").removeClass("is-visible")

        $("body").removeClass("overflow-hidden");
          // .one(
          //   "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          //   function() {
          //     $("body").removeClass("overflow-hidden");
          //   }
          // );
      } else {
        $(".cd-primary-nav").addClass("is-visible")
        $("body").addClass("overflow-hidden");
          // .one(
          //   "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          //   function() {
          //     $("body").addClass("overflow-hidden");
          //   }
          // );
      }
    });
  });
}
