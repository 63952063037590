import React, { useState, useEffect, useReducer, useRef } from "react"
import FormGroup from "react-bootstrap/lib/FormGroup"
import InputGroup from "react-bootstrap/lib/InputGroup"
import FormControl from "react-bootstrap/lib/FormControl"
import Button from "react-bootstrap/lib/Button"
import Overlay from "react-bootstrap/lib/Overlay"
import Tooltip from "react-bootstrap/lib/Tooltip"

import style from "./SearchPlacesForm.module"
import BrowserLocationHelper from "../utils/browser_location_helper"
import * as googleAPIs from "../apis/GoogleMapsAPI"
import AlertModalWithBody from "../ui-components/AlertModalWithBody"

// ----------------------------------------------------------------------------

function reducer(state, action) {
  switch (action.type) {
    case "place_suggestion_selected":
      return {
        ...state,
        currentText: action.payload.description,
        selectedSuggestion: action.payload,
        forceSearch: !state.forceSearch,
        isInFocus: false,
      }
    case "search_enabled":
      return { ...state, forceSearch: !state.forceSearch }
    case "set_currentText":
      return {
        ...state,
        currentText: action.payload,
        selectedSuggestion: undefined,
        selectedPlace: undefined,
        isInFocus: true,
      }
    case "set_isInFocus":
      return { ...state, isInFocus: action.payload }
    case "missing_street_number":
      return {
        ...state,
        selectedSuggestion: undefined,
        selectedPlace: action.payload,
        isStreetNumberModalVisible: true,
      }
    case "set_street_number":
      return {
        ...state,
        selectedSuggestion: undefined,
        selectedPlace: action.payload,
      }
    case "hide_street_number_modal":
      return { ...state, isStreetNumberModalVisible: false }
    case "set_placeError":
      return { ...state, selectedSuggestion: undefined, placeError: action.payload }
    default:
      throw new Error()
  }
}

function isAddressFilled(address) {
  return (
    !!address.latitude &&
    !!address.longitude &&
    !!address.street_name &&
    !!address.city
  )
}

function formatAddress(deliveryAddress) {
  return isAddressFilled(deliveryAddress)
    ? `${deliveryAddress?.street_name || ""}${
        deliveryAddress?.street_number
          ? " " + deliveryAddress?.street_number
          : ""
      }, ${deliveryAddress?.city}`
    : ""
}

function addressToPlace(address) {
  // address comes from the backend with slightly different keys
  let place = {}
  place[googleAPIs.ADDRESS_KEYS.LAT] = address.latitude
  place[googleAPIs.ADDRESS_KEYS.LNG] = address.longitude
  place[googleAPIs.ADDRESS_KEYS.COUNTRY] = address.country
  place[googleAPIs.ADDRESS_KEYS.CITY] = address.city
  place[googleAPIs.ADDRESS_KEYS.POSTAL_CODE] = address.postal_code
  place[googleAPIs.ADDRESS_KEYS.STREET_NAME] = address.street_name
  place[googleAPIs.ADDRESS_KEYS.STREET_NUMBER] = address.street_number
  return place
}

function SearchPlacesForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { placeholder = "", deliveryAddress = undefined, apiKey, path } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const formRef = useRef()

  const [suggestions, setSuggestions] = useState([])
  const [state, dispatch] = useReducer(reducer, {
    currentText: formatAddress(deliveryAddress),
    selectedPlace: addressToPlace(deliveryAddress),
    selectedSuggestion: undefined,
    forceSearch: undefined,
    isStreetNumberModalVisible: false,
    placeError: undefined,
    isInFocus: false,
  })

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!isValidText(state.currentText)) {
      return
    }

    async function getPredictions() {
      try {
        const res = await googleAPIs.getPlacePredictions(state.currentText)
        if (!res) {
          // empty
          setSuggestions([])
        } else {
          setSuggestions(res)
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error(error)
        }
      }
    }

    getPredictions()
  }, [state.currentText])

  useEffect(() => {
    // guard on component first mount
    if (state.forceSearch === undefined) {
      return
    }
    onSearch()
  }, [state.forceSearch])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function goToUrl(place) {
    let url = path
    url += "?latitude=" + place[googleAPIs.ADDRESS_KEYS.LAT]
    url += "&longitude=" + place[googleAPIs.ADDRESS_KEYS.LNG]
    url += "&city=" + place[googleAPIs.ADDRESS_KEYS.CITY]
    url += "&street_name=" + place[googleAPIs.ADDRESS_KEYS.STREET_NAME]
    if (!!place[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]) {
      url += "&street_number=" + place[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]
    }
    if (!!place[googleAPIs.ADDRESS_KEYS.POSTAL_CODE]) {
      url += "&postal_code=" + place[googleAPIs.ADDRESS_KEYS.POSTAL_CODE] // Google Maps do not returns postal if the query misses the street number
    }
    if (place[googleAPIs.ADDRESS_KEYS.FULL_ADDRESS]) {
      url += "&full_address=" + place[googleAPIs.ADDRESS_KEYS.FULL_ADDRESS]
    }
    url += "&venue_types=shop"
    BrowserLocationHelper.ChangeURL(url)
  }

  function isValidText(text) {
    return text.trim().length > 3
  }

  function isSameAddress(el) {
    return el.description === state.currentText.trim()
  }

  async function doGeocode() {
    if (
      !state.selectedPlace ||
      !state.selectedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]
    ) {
      return
    }
    // place contains infos
    let strAddress = `${
      state.selectedPlace[googleAPIs.ADDRESS_KEYS.STREET_NAME]
    }, ${state.selectedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]}, ${
      state.selectedPlace[googleAPIs.ADDRESS_KEYS.CITY]
    }, ${state.selectedPlace[googleAPIs.ADDRESS_KEYS.PROVINCE]}, ${
      state.selectedPlace[googleAPIs.ADDRESS_KEYS.COUNTRY]
    }`
    const geocodedPlace = await googleAPIs.getGeocode(strAddress)
    if (!geocodedPlace) {
      goToUrl(state.selectedPlace)
    } else {
      if (!geocodedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]) {
        // safeness check: if no street_number is returned force the modal input one
        geocodedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER] =
          state.selectedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]
      }
      goToUrl(geocodedPlace)
    }
  }

  async function onSearch() {
    // we have a place with all info we need
    if (
      state.selectedPlace &&
      state.selectedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER] &&
      state.selectedPlace[googleAPIs.ADDRESS_KEYS.LAT] &&
      state.selectedPlace[googleAPIs.ADDRESS_KEYS.LNG]
    ) {
      goToUrl(state.selectedPlace)
      return
    }

    // start checking the current search
    if (state.selectedSuggestion) {
      // we have clicked a suggestion
      getPlaceDetails(state.selectedSuggestion)
    } else if (!state.selectedPlace) {
      // we have searched with only text, search for a suggestion
      let suggestion = suggestions.find(isSameAddress)
      if (!suggestion) {
        dispatch({ type: "set_placeError", payload: true })
        return
      }
      getPlaceDetails(suggestion)
    } else {
      // we have a place with no street_number
      dispatch({ type: "missing_street_number", payload: state.selectedPlace })
    }
  }

  async function getPlaceDetails(suggestion) {
    try {
      let placeDet = await googleAPIs.getPlaceDetails(suggestion)
      if (!placeDet) {
        // empty
        dispatch({ type: "set_placeError", payload: true })
      } else {
        if (!placeDet[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]) {
          dispatch({ type: "missing_street_number", payload: placeDet })
        } else {
          goToUrl(placeDet)
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error(error)
      }
    }
  }

  function onAddressSelected(e, element) {
    if (!state.selectedPlace) {
      dispatch({ type: "place_suggestion_selected", payload: element })
    } else {
      handleSearch()
    }

    e.preventDefault()
  }

  function renderSuggestion(element) {
    return (
      <div
        key={element.place_id}
        className={style.address}
        onMouseDown={(e) => {
          onAddressSelected(e, element)
        }}
      >
        <i className={`fal fa-map-pin ${style.addressPin}`}></i>
        <p className={style.addressDescription}>{element.description}</p>
      </div>
    )
  }

  function handleEnterPressed(event) {
    if (event.keyCode === 13) {
      handleSearch()
      event.preventDefault()
    }
  }

  function handleSearch() {
    dispatch({ type: "search_enabled" })
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // -------------------------------------

  googleAPIs.initGoogleMapsIfNeeded(apiKey)

  return (
    <div className={props.className}>
      <div className={`${style.father}`}>
        <div
          className={`address-box ${style.container} ${
            isValidText(state.currentText) &&
            state.isInFocus &&
            suggestions.length > 0
              ? style.bottomBorder
              : style.bottomCornerRadius
          }`}
        >
          <form className={style.inputForm} autoComplete="off">
            <FormGroup
              controlId="formBasicText"
              style={{ marginBottom: 0 }}
              // validationState={this.getValidationState()}
            >
              <InputGroup className={style.shadow}>
                <FormControl
                  type="text"
                  ref={formRef}
                  className={style.formControl}
                  style={{ border: "none" }}
                  value={state.currentText}
                  placeholder={placeholder}
                  onKeyDown={(e) => {
                    handleEnterPressed(e)
                  }}
                  onFocus={() => {
                    dispatch({ type: "set_isInFocus", payload: true })
                  }}
                  onBlur={() => {
                    dispatch({ type: "set_isInFocus", payload: false })
                  }}
                  onChange={(e) =>
                    dispatch({
                      type: "set_currentText",
                      payload: e.target.value,
                    })
                  }
                />
                <InputGroup.Button>
                  <Button
                    aria-label="Cerca"
                    bsStyle="default"
                    className={style.search}
                    style={{ border: "none" }}
                    onClick={handleSearch}
                  >
                    <i className="fal fa-search search-icon" />
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </FormGroup>
          </form>

          {formRef && (
            <Overlay
              rootClose={true}
              show={state.placeError}
              target={formRef.current}
              onHide={() =>
                dispatch({ type: "set_placeError", payload: undefined })
              }
              placement="bottom"
            >
              <Tooltip id="warning-message">
                {I18n.t("search.wrong_address")}
              </Tooltip>
            </Overlay>
          )}
        </div>

        {isValidText(state.currentText) &&
          state.isInFocus &&
          suggestions.length > 0 && (
            <div className={style.suggestionsContainer}>
              <div className={style.topBorder}></div>
              {suggestions.map((el) => renderSuggestion(el))}
            </div>
          )}
      </div>

      <AlertModalWithBody
        show={state.isStreetNumberModalVisible}
        onHide={() => {
          dispatch({ type: "hide_street_number_modal" })
        }}
        onConfirm={doGeocode}
        title={I18n.t("search.street_number_title")}
        closeLabel={I18n.t("search.street_number_search")}
      >
        <FormControl
          type="text"
          value={
            (state.selectedPlace &&
              state.selectedPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER]) ||
            ""
          }
          placeholder={I18n.t("search.street_number_placeholder")}
          onChange={(e) => {
            let newPlace = { ...state.selectedPlace }
            newPlace[googleAPIs.ADDRESS_KEYS.STREET_NUMBER] = e.target.value
            dispatch({ type: "set_street_number", payload: newPlace })
          }}
          onKeyDown={(event) => {
            // TODO:: keycode deprecated
            if (event.keyCode === 13) {
              doGeocode()
            }
          }}
        />
      </AlertModalWithBody>
    </div>
  )
}

// ----------------------------------------------------------------------------

export default SearchPlacesForm
