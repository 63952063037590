import React, { useReducer, createContext, useContext, useEffect } from "react"
import { logAddToCart, logPurchase } from "../apis/Analytics/gtmActions"
import {
  registerOrderToFacebook,
  registerAddToCartToFacebook,
} from "../apis/Analytics/registerOrderToFacebook"
const storejs = require("store")

// ----------------------------------------------------------------------------

// Actions implemented by the reducer
export const CHANGE_STORE = "CHANGE_STORE"
export const UPDATE_DELIVERY_DETAILS = "UPDATE_DELIVERY_DETAILS"
export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const ADD_COUPON = "ADD_COUPON"
export const REMOVE_COUPON = "REMOVE_COUPON"
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD"
export const SET_CART_VISIBILITY = "SET_CART_VISIBILITY"
export const EMPTY_CART = "EMPTY_CART"
export const PLACE_ORDER = "PLACE_ORDER"
export const UPDATE_PAYMENT_INFO = "UPDATE_PAYMENT_INFO"
export const RESET_PAYMENT_INFO = "RESET_PAYMENT_INFO"
const REHYDRATE = "REHYDRATE"

function defaultCart() {
  return { products: {}, coupons: {} }
}

function defaultPaymentInfo() {
  return { createdAt: undefined, orderId: undefined }
}

function initialState() {
  return {
    store_id: null,
    carts: storejs.get("carts") || {},
    paymentMethod: { type: "cash", options: {} },
    paymentInfo: storejs.get("payment_info") || defaultPaymentInfo(),
    deliveryDetails: { price: 0, distance: 0, errors: [] },
    visible: false,
  }
}

const Context = createContext([initialState, () => {}])

/**
 * Returns the cart state in the Context and its dispatch function
 *
 * @returns { Array } i.e. [cartState, dispatchCartState]
 *
 * @example
 * const [cartState, dispatchCartState] = useCartStateStore()
 */
export const useCartStateStore = () => useContext(Context)

/**
 * Defines Cart Context
 *
 * @component
 *
 * @example
 *  return (
 *    <CartStateStore>
 *      <MyApp />
 *    </CartStateStore>
 */
export default function CartStateStore({ children, immutable = false }) {
  // ----------------------------------------------------------------------------
  // REDUCER
  // ----------------------------------------------------------------------------

  const reducer = (state, action) => {
    switch (action.type) {
      case CHANGE_STORE: {
        // change store (maybe better UPDATE_STORE) sets the current store updating all the info
        const store = action.payload
        const store_id = store.id
        if (!state.carts[store_id]) {
          state.carts[store_id] = defaultCart()
        }
        // Venues of type Shop do not have products (lazy loading)
        // updateProducts(store, state.carts[store_id])

        updatePromotion(store, state.carts[store_id])

        updateTotals(state.carts[store_id], state.deliveryDetails)

        // remove coupons
        state.carts[store_id].coupons = {}

        let newState = {
          ...state,
          store_id: store_id,
          carts: { ...state.carts },
        }
        persistCarts(newState.carts)
        return newState
      }
      case UPDATE_DELIVERY_DETAILS: {
        let deliveryDetails = {
          price: action.payload.price,
          errors: action.payload.errors,
        }

        updateTotals(state.carts[state.store_id], deliveryDetails)

        let newState = {
          ...state,
          carts: { ...state.carts },
          deliveryDetails: deliveryDetails,
        }
        persistCarts(newState.carts)
        return newState
      }
      case ADD_TO_CART: {
        let { sku, update, quantity } = action.payload
        let cart = getCart(state)

        if (cart.products[sku]) {
          cart.products[sku].quantity += quantity || 1
        } else {
          update.quantity = quantity || 1
          cart.products[sku] = update
        }

        updateTotals(cart, state.deliveryDetails)

        let newState = {
          ...state,
          carts: { ...state.carts, [state.store_id]: { ...cart } },
        }

        // log events
        logAddToCart(update, quantity || 1)
        registerAddToCartToFacebook((update.price / 100) * (quantity || 1))

        persistCarts(newState.carts)
        return newState
      }
      case REMOVE_FROM_CART: {
        let { sku } = action.payload
        let cart = getCart(state)
        let product = cart.products[sku]
        if ((product?.quantity || 1) === 1) {
          delete cart.products[sku]
        } else {
          product.quantity -= 1
        }

        updateTotals(cart, state.deliveryDetails)

        let newState = {
          ...state,
          carts: { ...state.carts, [state.store_id]: { ...cart } },
        }

        persistCarts(newState.carts)
        return newState
      }
      case ADD_COUPON: {
        let cart = getCart(state)
        cart.coupons[action.payload.code] = {
          code: action.payload.code,
          amount: action.payload.amount,
        }

        updateTotals(cart, state.deliveryDetails)

        let newState = {
          ...state,
          carts: { ...state.carts, [state.store_id]: { ...cart } },
        }

        persistCarts(newState.carts)
        return newState
      }
      case REMOVE_COUPON: {
        let cart = getCart(state)
        delete cart.coupons[action.payload.code]

        updateTotals(cart, state.deliveryDetails)

        let newState = {
          ...state,
          carts: { ...state.carts, [state.store_id]: { ...cart } },
        }

        persistCarts(newState.carts)
        return newState
      }
      case SET_CART_VISIBILITY:
        return {
          ...state,
          visible: action.payload,
        }
      case CHANGE_PAYMENT_METHOD:
        return {
          ...state,
          paymentMethod: {
            type: action.payload.type,
            options: action.payload.options || {},
          },
        }
      case UPDATE_PAYMENT_INFO: {
        let newState = {
          ...state,
          paymentInfo: {
            createdAt: action.payload.createdAt || state.paymentInfo.createdAt,
            orderId: action.payload.orderId || state.paymentInfo.orderId,
          },
        }
        persistPaymentInfo(newState.paymentInfo)
        return newState
      }
      case RESET_PAYMENT_INFO: {
        let newState = {
          ...state,
          paymentInfo: defaultPaymentInfo(),
        }
        persistPaymentInfo(newState.paymentInfo)
        return newState
      }
      case PLACE_ORDER: {
        let order = action.payload.order
        if (order && !order.fake) {
          // log events
          logPurchase(order)
          registerOrderToFacebook(order)
        }
        let newState = {
          ...state,
          carts: { ...state.carts, [action.payload.venue_id]: defaultCart() },
          paymentInfo: defaultPaymentInfo(),
        }
        persistCarts(newState.carts)
        persistPaymentInfo(newState.paymentInfo)
        return newState
      }
      case EMPTY_CART:
        let newState = {
          ...state,
          carts: { ...state.carts, [state.store_id]: defaultCart() },
        }
        persistCarts(newState.carts)
        return newState
      case REHYDRATE: {
        let newState = {
          ...state,
          carts: storejs.get("carts") || {},
        }
        return newState
      }
      default:
        return state
    }
  }

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const memoizedReducer = React.useCallback(reducer, [])
  const [state, dispatch] = useReducer(memoizedReducer, initialState())

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    // listens to show_cart events emitted by component out of the current React tree (i.e.: NavBarCartItem)
    window.addEventListener("show_cart", handleShowCart)

    // listens to storage changes to trigger update between tabs.
    // Doc says this event should not trigger on the window that
    // changes the storage (preventing useless re-rendering)
    !immutable && window.addEventListener("storage", handleStoreCartChange)

    return () => {
      window.removeEventListener("show_cart", handleShowCart)
      !immutable && window.removeEventListener("storage", handleStoreCartChange)
    }
  }, [])

  useEffect(() => {
    let cart = getCart(state)
    emitUpdateCount(cart.products)
  }, [state])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleShowCart() {
    dispatch({ type: SET_CART_VISIBILITY, payload: !state.visible })
  }

  function handleStoreCartChange() {
    dispatch({ type: REHYDRATE, payload: {} })
  }

  // function updateProducts(store, cart) {
  //   let unavailableProductKeys = []
  //   for (var key in cart.products) {
  //     let productID = cart.products[key].id
  //     let isProductAvailable = store.service_sections?.some((section) => {
  //       return section.services?.some((service) => {
  //         if (service.id === productID) {
  //           // update fields that can change
  //           cart.products[key] = {
  //             ...cart.products[key],
  //             name: service.name,
  //             section_title: service.section_title,
  //             original_price_cents: service.original_price_cents,
  //             price_cents: service.price_cents,
  //           }
  //         }
  //         return service.id === productID
  //       })
  //     })
  //     if (!isProductAvailable) {
  //       unavailableProductKeys.push(key)
  //     }
  //     // TODO: update additional parts prices
  //   }
  //   unavailableProductKeys.forEach((key) => {
  //     delete cart.products[key]
  //   })
  // }

  function updatePromotion(store, cart) {
    cart.promotion = store.promotion
  }

  function updateTotals(cart, delivery_details) {
    // caches prices inside the cart to avoid calculation at each render.
    // computes each product price total (additions included)
    // computes promotion prices if needed

    // reset totals
    cart.subtotal = 0
    cart.original_subtotal = 0
    cart.discount_amount = 0

    let products = cart.products
    for (var key in products) {
      let product = products[key]

      // reset product discount amount
      product.discount_amount = 0

      // compute product prices
      product.total_price_cents = product.price_cents * (product.quantity || 1)
      product.total_original_price_cents = product.original_price_cents
        ? product.original_price_cents * (product.quantity || 1)
        : undefined

      // add additional parts
      for (var key in product.additional_parts || {}) {
        product.total_price_cents +=
          product.additional_parts[key].price * (product.quantity || 1)
        if (product.original_price_cents) {
          product.total_original_price_cents +=
            product.additional_parts[key].price * (product.quantity || 1)
        }
      }

      // store product in totals
      cart.subtotal += product.total_price_cents
      cart.original_subtotal +=
        product.total_original_price_cents || product.total_price_cents
    }

    // compute percentage discount if needed
    let promotion = cart.promotion
    if (
      promotion?.type === "discounted_cart" &&
      cart.subtotal >= promotion?.minimum_amount_cents
    ) {
      let discount = (cart.subtotal * promotion.percentage) / 100
      cart.discount_amount = discount
      cart.subtotal -= discount

      // apply discount to products
      for (var key in products) {
        let product = products[key]
        // compute discount and save it
        let discount = (product.total_price_cents * promotion.percentage) / 100
        product.discount_amount = discount
        if (!product.total_original_price_cents) {
          // if there was no discount, store the current price as the original
          product.total_original_price_cents = product.total_price_cents
        }
        product.total_price_cents -= discount
      }
    }

    // store subtotal
    cart.original_subtotal =
      cart.original_subtotal !== cart.subtotal
        ? cart.original_subtotal
        : undefined

    // store total
    cart.total = cart.subtotal + (delivery_details?.price || 0)
    cart.original_total = cart.original_subtotal
      ? cart.original_subtotal + (delivery_details?.price || 0)
      : undefined

    // compute coupons
    let coupons = cart.coupons
    for (var coupon in coupons) {
      cart.total -= coupons[coupon].amount
      if (cart.original_total) {
        cart.original_total -= coupons[coupon].amount
      }
    }
  }

  function persistCarts(carts) {
    storejs.set("carts", { ...carts })
  }

  function persistPaymentInfo(payment) {
    storejs.set("payment_info", { ...payment })
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // -------------------------------------

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  )
}

// ----------------------------------------------------------------------------
// UTILS
// ----------------------------------------------------------------------------

/**
 * Returns the cart stored in localstorage for the store currently in use
 *
 * @param {Object} state cartState in use
 * @returns {Object} the cart for the current store
 */
export function getCart(state) {
  if (!state?.store_id) return defaultCart()
  return state?.carts[state.store_id] || defaultCart()
}

/**
 * Return true if the promotion for the current store is active then called
 *
 * @param {Object} cart cart of the store in use
 * @returns {Boolean} true if the promotion is active
 */
export function isDiscountedCartActive(cart) {
  if (!cart) return false
  if (!cart.promotion) return false
  if (!cart.promotion.minimum_amount_cents) return false

  return (
    cart.promotion.type === "discounted_cart" &&
    (cart.original_subtotal || 0) >= (cart.promotion.minimum_amount_cents || 0)
  )
}

/**
 * Return the count of products, considering the quantity
 *
 * @param {Object} products object containing products of the cart
 * @returns {Number} the count of products
 */
export function productsCount(products) {
  let count = 0
  for (var key in products) {
    count += products[key].quantity || 1
  }
  return count
}

function emitUpdateCount(products) {
  const event = new CustomEvent("cart_count_changed", {
    detail: productsCount(products),
  })
  window.dispatchEvent(event)
}

/**
 * Returns the count of products for the store of interest (considering quantity)
 * i.e.: to get the initial items count in components out of the Context React Tree.
 *
 * @param {String} store_id id of the store of interest
 * @returns {Number} the count of products
 */
export function getInitialCartCount(store_id) {
  if (!store_id) return 0

  let carts = storejs.get("carts") || {}
  let cart = carts[store_id]
  if (!cart) return 0

  return productsCount(cart.products)
}
