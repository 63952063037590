import React from "react"
import Modal from "react-bootstrap/lib/Modal"
import Button from "react-bootstrap/lib/Button"

function AlertModalWithBody(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const {
    show,
    onHide,
    onConfirm,
    title,
    closeLabel,
    isTiny = true,
    shadow = false,
  } = props
  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // -------------------------------------
  return (
    <Modal
      className={`modal ${isTiny ? "modal--tiny" : ""} ${
        shadow ? "modal--shadow" : ""
      }`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ maxHeight: "unset" }}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={onConfirm}>
          {closeLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AlertModalWithBody
